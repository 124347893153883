import { useParams } from "react-router-dom";
// import BodyParts from "../constants/BodyParts";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MapContext from "../MapContext";
import { useContext } from "react";

export default function IndicationView() {
  const Map = useContext(MapContext);
  const { indicationName } = useParams();
  var indication = undefined;
  var indicationTreatments = [];
  var treatments = [];

  console.log("Rendering indication view for indication: ", indicationName);

  var loaded = false;
  if (
    Map !== undefined &&
    "data" in Map &&
    typeof Map.data === "object" &&
    "areas" in Map.data
  ) {
    loaded = true;
    for(const i in Map.data.indications){
      if(Map.data.indications[i].name === indicationName){
        indication = Map.data.indications[i];
        break;
      }
    }
    indicationTreatments = Map.data.indicationTreatments[indication.id] || [];
    treatments = indicationTreatments.map(
      (it) => Map.data.treatments[it.treatment_id]
    );
  }

  const has_treatments = treatments.length > 0;
  if (!loaded) return <h1>Loading</h1>;
  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Grid item xs={12} style={{ display: has_treatments ? "block" : "none" }}>
        <h3>For {indication.name} you can choose any of the following options</h3>
        {treatments.map((treatment) => (
          <Button
            fullWidth
            variant="contained"
            color="success"
            href={`#/treatment/${treatment.id}`}
            sx={{ mt: 3, mb: 2 }}
            key={treatment.id}
          >
            {treatment.name}
          </Button>
        ))}
      </Grid>
      <Grid>
        {!has_treatments && (
          <p>Looks like no treatments has been added for this indication yet</p>
        )}
      </Grid>
    </Container>
  );
}
