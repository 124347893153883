import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  Vector3,
  Vector2,
  Raycaster,
  Object3D,
  Mesh,
  BufferGeometry,
  MeshBasicMaterial,
  Texture,
  Color,
  PMREMGenerator,
  UnsignedByteType,
  ACESFilmicToneMapping,
  sRGBEncoding
} from "three";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import Button from "@mui/material/Button";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ProgressContext from "./ProgressContext";

const cameraHeight = 0;
const cameraInitialHorizontalDistance = 5;

function getCamera(width: number, height: number) {
  const fieldOfView = 25;
  const nearClippingPlane = 0.1;
  const farClippingPlane = 10000;
  const camera = new PerspectiveCamera(
    fieldOfView,
    width / height,
    nearClippingPlane,
    farClippingPlane
  );
  camera.position.x = 0;
  camera.position.y = cameraHeight;
  camera.position.z = cameraInitialHorizontalDistance;
  return camera;
}

function getControls(camera: PerspectiveCamera, renderer: WebGLRenderer) {
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.autoRotate = false; // Disable auto-rotation
  controls.maxDistance = 5;
  controls.minDistance = 1.5;
  controls.target = new Vector3(0, cameraHeight, 0);
  return controls;
}

function getRenderer(width: number, height: number) {
  const renderer = new WebGLRenderer({ antialias: true });
  renderer.setSize(width, height);
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.outputEncoding = sRGBEncoding;
  renderer.physicallyCorrectLights = true;
  return renderer;
}

interface View3DProps {
  width: number;
  height: number;
  objects: Object3D[];
}

interface ObjectSelection {
  object: Mesh<BufferGeometry, MeshBasicMaterial>;
  originalTexture: Texture | null;
  originalColor: Color;
}

export default function View3D(props: View3DProps) {
  const { width, height, objects } = props;
  const scene = useRef(undefined as undefined | Scene);
  const camera = useRef(undefined as undefined | PerspectiveCamera);
  const renderer = useRef(undefined as undefined | WebGLRenderer);
  const controls = useRef(undefined as undefined | OrbitControls);
  const raycaster = useRef(undefined as undefined | Raycaster);
  const [pointer, setPointer] = useState({ x: 1, y: 1, pointerDown: false } as {
    x: number;
    y: number;
    pointerDown: boolean;
  });
  const view = useRef(undefined as any);
  const selection = useRef(undefined as undefined | ObjectSelection);
  const setSelection = (newVal: undefined | ObjectSelection) =>
    (selection.current = newVal);
  const { setNextPage } = useContext(ProgressContext);

  useEffect(() => {
    if (camera.current) {
      // Change canvas size of current camera
    } else {
      // Create a new camera
      camera.current = getCamera(width, height);
    }
    if (renderer.current) {
    } else {
      renderer.current = getRenderer(width, height);
    }
    setPointer({ x: -100, y: -100, pointerDown: false });
  }, [width, height]);
  useEffect(() => {
    // Called only once to set the view
    if (camera.current && renderer.current) {
      controls.current = getControls(camera.current, renderer.current);
    }
    if (!scene.current) scene.current = new Scene();
    scene.current.background = new Color(0x888888);
    if (!raycaster.current) raycaster.current = new Raycaster();
    if (view.current && renderer.current) {
      view.current.appendChild(renderer.current.domElement);
      var lastPointerUpdate = new Date();
      function onPointerMove(event: any) {
        console.log("Pointer event detected ", event);
        const pointerDown = Boolean(event.pressure);
        const currentTime = new Date();
        if (currentTime.getTime() - lastPointerUpdate.getTime() < 100) {
          return;
        }
        const x = event.pageX - event.currentTarget.offsetLeft;
        const y = event.pageY - event.currentTarget.offsetTop;
        const pointer_x = (x / width) * 2 - 1;
        const pointer_y = (-y / height) * 2 + 1;
        const newPointer = { x: pointer_x, y: pointer_y, pointerDown };
        setPointer(newPointer);
        lastPointerUpdate = currentTime;
      }
      const rerenderScene = () => {
        controls.current?.update();
        if (renderer.current && scene.current && camera.current)
          renderer.current.render(scene.current, camera.current);
      };
      view.current.addEventListener("pointermove", rerenderScene);
      view.current.addEventListener("pointerdown", onPointerMove);
      view.current.addEventListener("wheel", rerenderScene);

      while (scene.current.children.length > 0) {
        scene.current.remove(scene.current.children[0]);
      }
      for (const key in objects) {
        const object = objects[key];
        if (object.parent !== scene.current) {
          scene.current.add(object);
        }
      }

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const v = view.current;
        v.removeEventListener("pointermove", onPointerMove);
        v.removeEventListener("pointerdown", onPointerMove);
        v.removeEventListener("scroll", onPointerMove);
      };
    }
    setPointer({ x: -100, y: -100, pointerDown: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, renderer.current, height, width]);

  if (
    scene.current &&
    camera.current &&
    renderer.current &&
    controls.current &&
    raycaster.current
  ) {
    raycaster.current.setFromCamera(
      new Vector2(pointer.x, pointer.y),
      camera.current
    );
    const intersects = raycaster.current.intersectObjects(
      scene.current.children
    );
    if (pointer.pointerDown) {
      if (intersects.length) {
        //console.log("Intersected objects: ", intersects)
        const object = intersects[0].object as Mesh<
          BufferGeometry,
          MeshBasicMaterial
        >;
        var restore_required = false;
        var update_required = true;
        if (selection.current) {
          if (selection.current.object === object) {
            update_required = false;
          } else {
            restore_required = true;
          }
        }
        if (restore_required && selection.current) {
          // Restore object original color
          console.log(
            "Restoring...",
            selection.current.originalColor,
            selection.current.originalTexture
          );
          selection.current.object?.material.color.set(
            selection.current.originalColor
          );
          selection.current.object.material.map =
            selection.current.originalTexture;
          selection.current.object.material.needsUpdate = true;
        }
        if (update_required) {
          // Update selection
          setSelection({
            object: object,
            originalColor: object.material.color.clone(),
            originalTexture: object.material.map?.clone() || null,
          });
          setNextPage(`/area/${object.name}`);
          console.log("Selected Object updated to: ", object);
          // Highlight Selected Object
          const newMaterial = object.material.clone();
          newMaterial.color.set(0x000077);
          newMaterial.map = null;
          object.material = newMaterial;
          object.material.needsUpdate = true;
        }
      } else if (selection.current) {
        // Restore object original color
        console.log(
          "Restoring only...\nColor:",
          selection.current.originalColor,
          "\nTexture:",
          selection.current.originalTexture
        );
        selection.current.object.material.color.set(
          selection.current.originalColor
        );
        selection.current.object.material.map =
          selection.current.originalTexture?.clone() || null;
        selection.current.object.material.needsUpdate = true;
        // Clear selection
        setSelection(undefined);
        setNextPage(undefined);
      }
    }
    controls.current.update();
    renderer.current.render(scene.current, camera.current);
  }

  const handleConfirm = (
    event: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    console.log("Confirm clicked ", event);
    console.log("Confirming ", selection.current?.object.name);
    window.location.href = `/#/area/${selection.current?.object.name}`;
  };
  return (
    <>
      <div ref={view} style={{ width, height }}></div>
      {false && (
        <Button
          style={{
            position: "fixed",
            bottom: 20,
            left: 20,
            right: 20,
          }}
          variant="contained"
          startIcon={<CheckBoxOutlineBlankIcon />}
          onClick={handleConfirm}
        >
          {/*selection.current.object.name*/}?
        </Button>
      )}
    </>
  );
}
