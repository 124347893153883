import { useParams } from "react-router-dom";
import View3D from "../components/View3D";
// import { PointLight } from "three";
import { AmbientLight, DirectionalLight } from "three";
import { useState, useEffect, useRef } from "react";
import ThreeDObject from "../components/ThreeDObject";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


var objects: any = [
  new AmbientLight(0xffffff, 2),
  new DirectionalLight(0xffffff, 0.5)
];
objects[1].position.set(0, 1, 0);


function dispose3DMaterials(group: any) {
  console.log("Attempting to dispose ", group);
  for (const i in group.children) {
    if (group.children[i].type === "Group") {
      dispose3DMaterials(group.children[i]);
    } else {
      group.children[i].geometry.dispose();
      group.children[i].material.dispose();
    }
  }
}

export default function AreaSelector() {
  const [loading, setLoading] = useState(true);
  const { gender } = useParams();
  const containerRef = useRef(null) as any;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (gender) {
      ThreeDObject(`${gender.toLowerCase()}/${gender.toLowerCase()}.gltf`).then(
        (model: any) => {
          objects.push(model.scene);
          setLoading(false);
        }
      );
      return () => {
        console.log("Unmounting 3D World", objects);
        if (objects.length > 6) {
          console.log("About to dispose ", objects.slice(6));
          for (const i in objects.slice(6)) {
            dispose3DMaterials(objects[6 + parseInt(i)]);
          }
          objects.splice(6);
        }
      };
    } else {
      console.error("No gender provided to the AreaSelector");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Check if ref is assigned
    if(containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;
      console.log("Setting view dimentions")
      console.log(containerWidth)
      console.log(containerHeight)
      setWidth(containerWidth);
      setHeight(containerHeight);
    }

    // ...
  }, [containerRef.current]); // Re-run when ref value changes

  return (
    <div ref={containerRef} style={{height: "100%", borderBottom: "1px solid lightgray", overflow: "hidden"}}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && <View3D width={width} height={height} objects={objects} />}
    </div>
  );
}
