import "./App.css";
import Map from "./components/Map";
import { MapContextProvider } from "./MapContext";
import { ProgressContextProvider } from "./components/ProgressContext";

function App() {
  return (
    <MapContextProvider>
      <ProgressContextProvider>
        <div className="App">
          <div className="App-header">
            <Map />
          </div>
        </div>
      </ProgressContextProvider>
    </MapContextProvider>
  );
}

export default App;
