import { IconButton, Typography, useMediaQuery, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InfoIcon from "@mui/icons-material/Info";
import QuizIcon from "@mui/icons-material/Quiz";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import CollectionsIcon from "@mui/icons-material/Collections";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useState } from "react";
import FaqWidget from "./FAQWidget";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "50vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};


function VideoWidget(props: {
  open: boolean;
  handleClose: () => void;
  indication: any;
}) {
  const { open, handleClose, indication } = props;
  const src = `${indication.video}?autoplay=1&fs=1&iv_load_policy=3&rel=0&showinfo=0`;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
          Text in a modal
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography> */}
        <iframe
          title={indication.name}
          allowFullScreen
          src={src}
          width="100%"
          style={{ border: "none", height: "50vw" }}
        />
      </Box>
    </Modal>
  );
}


function IndicationBar(props: { indication: any }) {
  const { indication } = props;
  const theme = useTheme();
  const isMdScreenOrLarger = useMediaQuery(theme.breakpoints.up("md"));
  const [reading, setReading] = useState(false);

  const [videoOpen, setVideoOpen] = useState(false);
  const handleOpenVideo = () => setVideoOpen(true);
  const handleCloseVideo = () => setVideoOpen(false);

  const [faqOpen, setFaqOpen] = useState(false);
  const handleOpenFaq = () => setFaqOpen(true);
  const handleCloseFaq = () => setFaqOpen(false);

  return (
    <Stack direction="row" justifyContent="space-between">
      {reading ? (
        <IconButton
          sx={{ mr: 1 }}
          onClick={() => window.speechSynthesis.cancel()}
        >
          <StopCircleIcon />
          {isMdScreenOrLarger && (
            <Typography variant="subtitle1">Stop</Typography>
          )}
        </IconButton>
      ) : (
        <IconButton
          sx={{ mr: 1 }}
          onClick={() => {
            if (window.speechSynthesis.speaking) {
              window.speechSynthesis.cancel();
            }
            const utterance = new SpeechSynthesisUtterance(
              indication.description
            );
            utterance.onend = (ev) => setReading(false);
            utterance.onerror = (er) => setReading(false);
            setReading(true);
            window.speechSynthesis.speak(utterance);
          }}
        >
          <VolumeUpIcon />
          {isMdScreenOrLarger && (
            <Typography variant="subtitle1">Listen</Typography>
          )}
        </IconButton>
      )}
      <IconButton
        disabled={Boolean(!indication.video)}
        onClick={() => {
          handleOpenVideo();
        }}
        sx={{ mr: 1 }}
      >
        <YouTubeIcon />
        {isMdScreenOrLarger && (
          <Typography variant="subtitle1">Watch</Typography>
        )}
      </IconButton>
      <IconButton
        disabled={Boolean(!indication.faq)}
        onClick={() => {
          handleOpenFaq();
        }}
      >
        <QuizIcon />
        {isMdScreenOrLarger && <Typography variant="subtitle1">FAQ</Typography>}
      </IconButton>
      <IconButton>
        <CollectionsIcon />
        {isMdScreenOrLarger && (
          <Typography variant="subtitle1">Photos</Typography>
        )}
      </IconButton>
      <IconButton
        disabled={Boolean(!indication.info)}
        onClick={() => window.open(indication.info, "_blank")}
      >
        <InfoIcon />
        {isMdScreenOrLarger && (
          <Typography variant="subtitle1">More</Typography>
        )}
      </IconButton>
      { Boolean(indication.video) &&
        <VideoWidget
        handleClose={handleCloseVideo}
        open={videoOpen}
        indication={indication}
      />}
      { Boolean(indication.faq) && 
        <FaqWidget
        handleClose={handleCloseFaq}
        open={faqOpen}
        indication={indication}
      />}
    </Stack>
  );
}

export default IndicationBar;
