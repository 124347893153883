const code_name = {
  XXB02: "FULL FACE & NECK GENERAL",
  XXB03: "UPPER FACE GENERAL",
  XXB04: "MID FACE GENERAL",
  XXB05: "LOWER FACE GENERAL",
  XXB06: "FRONT HEAD",
  XXB07: "GLABELLA",
  XXB08: "EYEBROWS",
  XXB09: "EYELIDS",
  XXB10: "CROWS FEET",
  XXB11: "EYELASHES",
  XXB12: "UNDEREYES",
  XXB13: "EARS",
  XXB14: "NOSE",
  XXB15: "CHEEKS",
  XXB16: "LIPS",
  XXB17: "MARIONETTE LINE",
  XXB18: "JAW LINE",
  XXB19: "CHIN",
  XXC01: "DOUBLE CHIN",
  XXC02: "NECK",
  XXD01: "Décolleté",
  XXD02: "BREAST / CHEST AREA",
  XXD03: "ABDOMINAL MUSCLES",
  XXD04: "ABDOMINAL FAT",
  XXD05: "ABDOMINAL SKIN",
  XXE01: "BACK AREA MUSCLES",
  XXE02: "BACK AREA FAT",
  XXE03: "BACK AREA SKIN",
  XXF01: "UNDER ARMS",
  XXF02: "ARM FRONT SIDE",
  XXF03: "ARM BACK SIDE",
  XXF04: "ELBOW",
  XXG01: "BACK HANDS",
  XXG02: "NAILS",
  XXH01: "PRIVATE AREA SKIN",
  XXH02: "GENITALS",
  XXI01: "BUTTOCKS",
  XXI02: "THIGHS",
  XXA01: "HEAD HAIR",
  XXA02: "BEARD HAIR",
  XXA03: "BODY HAIR",
  XXB01: "FULL FACE GENERL",
  XXI03: "KNEES",
  XXI04: "CALF",
  XXI05: "HEELS",
  XXJ01: "FOOT SKIN",
  XXJ02: "NAILS",
};

const codes = {
  FULL_FACE_NECK_GENERAL: "XXB02",
  UPPER_FACE_GENERAL: "XXB03",
  MID_FACE_GENERAL: "XXB04",
  LOWER_FACE_GENERAL: "XXB05",
  FRONT_HEAD: "XXB06",
  GLABELLA: "XXB07",
  EYEBROWS: "XXB08",
  EYELIDS: "XXB09",
  CROWS_FEET: "XXB10",
  EYELASHES: "XXB11",
  UNDEREYES: "XXB12",
  EARS: "XXB13",
  NOSE: "XXB14",
  CHEEKS: "XXB15",
  LIPS: "XXB16",
  MARIONETTE_LINE: "XXB17",
  JAW_LINE: "XXB18",
  CHIN: "XXB19",
  DOUBLE_CHIN: "XXC01",
  NECK: "XXC02",
  Décolleté: "XXD01",
  BREAST_CHEST_AREA: "XXD02",
  ABDOMINAL_MUSCLES: "XXD03",
  ABDOMINAL_FAT: "XXD04",
  ABDOMINAL_SKIN: "XXD05",
  BACK_AREA_MUSCLES: "XXE01",
  BACK_AREA_FAT: "XXE02",
  BACK_AREA_SKIN: "XXE03",
  UNDER_ARMS: "XXF01",
  ARM_FRONT_SIDE: "XXF02",
  ARM_BACK_SIDE: "XXF03",
  ELBOW: "XXF04",
  BACK_HANDS: "XXG01",
  NAILS: "XXG02",
  PRIVATE_AREA_SKIN: "XXH01",
  GENITALS: "XXH02",
  BUTTOCKS: "XXI01",
  THIGHS: "XXI02",
  HEAD_HAIR: "XXA01",
  BEARD_HAIR: "XXA02",
  BODY_HAIR: "XXA03",
  FULL_FACE_GENERL: "XXB01",
  KNEES: "XXI03",
  CALF: "XXI04",
  HEELS: "XXI05",
  FOOT_SKIN: "XXJ01",
};

const areas = [
  { name: "Glabella", group: "Face", codes: [codes.GLABELLA] },
  { name: "Eyebrows", group: "Face", codes: [codes.EYEBROWS] },
  { name: "Eyelids", group: "Face", codes: [codes.EYELIDS] },
  { name: "Eyelashes", group: "Face", codes: [codes.EYELASHES] },
  { name: "Undereyes", group: "Face", codes: [codes.UNDEREYES] },
  { name: "Ears", group: "Face", codes: [codes.EARS] },
  { name: "Nose", group: "Face", codes: [codes.NOSE] },
  { name: "Face", group: "Face", codes: [codes.FULL_FACE_GENERL] },
  { name: "Scalp", group: "HeadAndLowerFace", codes: [codes.HEAD_HAIR] },
  { name: "Cheeks", group: "HeadAndLowerFace", codes: [codes.CHEEKS] },
  { name: "Lips", group: "HeadAndLowerFace", codes: [codes.LIPS] },
  { name: "Jaw Line", group: "HeadAndLowerFace", codes: [codes.JAW_LINE] },
  { name: "Chin", group: "HeadAndLowerFace", codes: [codes.CHIN] },
  {
    name: "Double Chin",
    group: "HeadAndLowerFace",
    codes: [codes.DOUBLE_CHIN],
  },
  { name: "Neck", group: "HeadAndLowerFace", codes: [codes.NECK] },
  { name: "Hand Nails", group: "Arms", codes: [codes.NAILS] },
  { name: "Backhand", group: "Arms", codes: [codes.BACK_HANDS] },
  { name: "Hand", group: "Arms", codes: [] }, // Whatever left after separating Backhand (has no areas)
  { name: "Arm Back Side", group: "Arms", codes: [codes.ARM_BACK_SIDE] },
  { name: "Elbow", group: "Arms", codes: [codes.ELBOW] },
  { name: "Arm Front Side", group: "Arms", codes: [codes.ARM_FRONT_SIDE] },
  { name: "Armpit", group: "Arms", codes: [codes.UNDER_ARMS] },
  { name: "Decollete", group: "UpperMiddle", codes: [codes.Décolleté] }, // Not in man, or child. Women only
  {
    name: "Back",
    group: "UpperMiddle",
    codes: [codes.BACK_AREA_FAT, codes.BACK_AREA_MUSCLES, codes.BACK_AREA_SKIN],
  },
  {
    name: "Abdomen",
    group: "UpperMiddle",
    codes: [codes.ABDOMINAL_FAT, codes.ABDOMINAL_MUSCLES, codes.ABDOMINAL_SKIN],
  },
  { name: "Chest", group: "UpperMiddle", codes: [codes.BREAST_CHEST_AREA] },
  { name: "Buttocks", group: "Middle", codes: [codes.BUTTOCKS] },
  { name: "Genitals", group: "Middle", codes: [codes.GENITALS] },
  { name: "Private Area", group: "Middle", codes: [codes.PRIVATE_AREA_SKIN] },
  { name: "Feet Nails", group: "LowerHalf", codes: [codes.NAILS] },
  { name: "Heels", group: "LowerHalf", codes: [codes.HEELS] },
  { name: "Feet", group: "LowerHalf", codes: [codes.FOOT_SKIN] }, // Everything left in feet after separating the above
  { name: "Calf", group: "LowerHalf", codes: [codes.CALF] },
  { name: "Knees", group: "LowerHalf", codes: [codes.KNEES] },
  { name: "Thighs", group: "LowerHalf", codes: [codes.THIGHS] },
  { name: "Legs", group: "LowerHalf", codes: [codes.THIGHS] }, // Everything left after separating the above
];

export { code_name, codes, areas };
