//import Body from '../views/Body';
import { HashRouter, Routes, Route } from "react-router-dom";
import GenderSelection from "../views/GenderSelection";
//import MainPart from '../views/MainPart';
import ChildPart from "../views/ChildPart";
import IndicationView from "../views/IndicationView";
import AreaSelector from "../views/AreaSelector";

import Box from "@mui/material/Box";
//import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ProgressContext from "./ProgressContext";
import { useContext } from "react";

const steps = ["Gender", "Treatment Area", "Indication"];

export default function Map(props) {
  const { next, back, nextDisabled, backDisabled, step } =
    useContext(ProgressContext);
  const activeStep = step;
  return (
    <Container maxWidth="lg" style={{ height: "100vh"}}>
      <Box style={{ height: "90px", border: "1px solid lightgray", borderBottom: "none"}}>
        <div style={{ height: 30 }}></div>
        <Stepper
          activeStep={activeStep}
          style={{ height: 60 }}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box style={{ height: "calc(90vh - 150px)", border: "1px solid lightgray", borderBottom: "none" }}>
        <HashRouter>
          <Routes>
            <Route exact path="/" element={<GenderSelection />} />
            <Route exact path="/view/:gender" element={<AreaSelector />} />
            {/* <Route exact path="/Part/:partName" element={<MainPart />} /> */}
            <Route
              exact
              path="/view/:gender/area/:area"
              element={<ChildPart />}
            />
            <Route
              exact
              path="/view/:gender/area/:area/indication/:indicationName"
              element={<IndicationView />}
            />
          </Routes>
        </HashRouter>
      </Box>
      <Box
        style={{ height: "60px", borderRadius: "0 0 5px 5px" , border: "1px solid lightgray", borderTop: "none" }}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <Button
          color="inherit"
          disabled={activeStep === 0 || backDisabled}
          onClick={back}
          variant="contained"
          size="large"
          style={{width: "40vw", maxWidth: "200px"}}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {activeStep < steps.length && (
          <Button size="large" color="success" style={{width: "40vw", maxWidth: "200px"}} disabled={nextDisabled} onClick={next} variant="contained">
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        )}
      </Box>
    </Container>
  );
}
