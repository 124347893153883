import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

function ModalHeader(props: { handleClose: () => void, setFilter: (value: string) => void }) {
  const { handleClose, setFilter } = props;
  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        borderRadius: 0,
      }}
    >
      {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
        <MenuIcon />
      </IconButton> */}
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Frequently Asked Questions"
        inputProps={{ "aria-label": "search frequently asked questions" }}
        onChange={(e) => setFilter(e.target.value)}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton onClick={handleClose} color="primary" sx={{ p: "10px" }} aria-label="directions">
        <CloseIcon />
      </IconButton>
    </Paper>
  );
}

const modalContainerStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const faqContentStyle = {
  width: "100%",
  height: "80vh",
};

const Accordion = styled((props: any) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function FaqWidget(props: {
  open: boolean;
  handleClose: () => void;
  indication: any;
}) {
  const { open, handleClose, indication } = props;
  const [filter, setFilter] = useState("");
  const faq = indication.faq
    .split("\n\n") 
    .filter((i: string) => i.length)
    .map((i: string) => {
      const items = i.split("?", 2);
      return { q: `${items[0].trim()}?`, a: items[1].trim() };
    }).filter((qa:any) => qa.q.toLowerCase().includes(filter.toLowerCase()));
  console.log("faq:", faq);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalContainerStyle}>
        <ModalHeader handleClose={handleClose} setFilter={setFilter} />
        <Box sx={faqContentStyle} overflow={"scroll"}>
          {faq.map((qa: { q: string; a: string }) => (
            <Accordion key={qa.a}>
              <AccordionSummary>{qa.q}</AccordionSummary>
              <AccordionDetails>{qa.a}</AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}

export default FaqWidget;
