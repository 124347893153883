import Carousel from "nuka-carousel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import ProgressContext from "../components/ProgressContext";
import { useContext, useEffect } from "react";

const genders = ["/view/Man", "/view/Woman", "/view/Child"];

/*
export default function GenderSelection() {
  const { setNextPage } = useContext(ProgressContext) as any;
  useEffect(() => {
    setNextPage(genders[0]);
  }, [setNextPage]);
  return (
    <Carousel
      wrapAround
      style={{borderBottom: "1px solid lightgray"}}
      afterSlide={(i) => setNextPage(genders[i])}
      defaultControlsConfig={{
        prevButtonClassName: "SlideButton",
        nextButtonClassName: "SlideButton",
        prevButtonText: (
          <IconButton color="primary">
            <ArrowBackIosIcon />
          </IconButton>
        ),
        nextButtonText: (
          <IconButton color="primary">
            <ArrowForwardIosIcon />
          </IconButton>
        ),
      }}
    >
      <div
        style={{ backgroundImage: "url('/man.png')" }}
        className="GenderSlide"
      ></div>

      <div
        style={{ backgroundImage: "url('/woman.png')" }}
        className="GenderSlide"
      ></div>

      <div
        style={{ backgroundImage: "url('/child.png')" }}
        className="GenderSlide"
      ></div>
    </Carousel>
  );
}
*/

export default function GenderSelection() {
  const { setNextPage } = useContext(ProgressContext) as any;
  useEffect(() => {
    setNextPage(genders[0]);
  }, [setNextPage]);
  return (
    <div className="card-container">
      <a href={`${window.location.origin}/#${genders[0]}`} target="_top">
        <div style={{ backgroundImage: "url('/man.png')" }} className="card">
          <p>Man</p>
        </div>
      </a>

      <a href={`${window.location.origin}/#${genders[1]}`} target="_top">
        <div style={{ backgroundImage: "url('/woman.png')" }} className="card">
          <p>Woman</p>
        </div>
      </a>

      <a href={`${window.location.origin}/#${genders[2]}`} target="_top">
        <div style={{ backgroundImage: "url('/child.png')" }} className="card">
          <p>Child</p>
        </div>
      </a>
    </div>
  );
}
