import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MapContext from "../MapContext";
import { useContext, useState } from "react";
import { areas } from "../constants/BodyParts/areaCodes";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ProgressContext from "../components/ProgressContext";
//import VolumeUpIcon from '@mui/icons-material/VolumeUp';
//import IconButton from '@mui/material/IconButton';
import IndicationBar from "../components/IndicationBar";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ChildPart() {
  const Map = useContext(MapContext);
  const { setNextPage } = useContext(ProgressContext);
  const [expanded, setExpanded] = useState("panel1");
  var { area } = useParams();
  area = area.replaceAll('_', ' ');
  const handleChange = (panel) => (event, newExpanded) => {
    setNextPage(newExpanded ? `/indication/${panel}` : undefined);
    setExpanded(newExpanded ? panel : false);
  };
  var indications = [];
  console.log("Current area: ", area);
  var area_codes = undefined;
  var area_list = undefined;
  var loaded = false;
  if (
    Map !== undefined &&
    "data" in Map &&
    typeof Map.data === "object" &&
    "areas" in Map.data
  ) {
    loaded = true;
    console.log("Searching for area ", area, " in ", areas);
    area_codes = areas.find(
      (a) =>
        a.name.toLowerCase().replaceAll(" ", "_") ===
        area.toLowerCase().replaceAll(" ", "_")
    );
    console.log("Detected Area Codes: ", area_codes.codes);
    console.log("Search in areas: ", Map.data.areas);
    area_list = Map.data.areas.filter((a) =>
      area_codes.codes.includes(a.code)
    );
    if(area_list.length > 1){

    }
    console.log("Area list:", area_list);
    console.log("Map.data.areaIndications: ", Map.data.areaIndications);
    var grouped_indications = area_list.map(
      (a) => Map.data.areaIndications[a.id] || []
    )
    indications = []
      .concat(...area_list.map((a) => Map.data.areaIndications[a.id] || []))
      .map((ai) => Map.data.indications[ai.indication_id]);
    console.log("grouped_indications: ", grouped_indications);
  }
  console.log("indications: ", indications);
  const has_indications = indications.length > 0;
  if (!loaded) return <h1>Loading</h1>;
  return (
    <div
      style={{
        overflowY: "scroll",
        height: "calc(90vh - 170px)",
        padding: 0,
        margin: 0,
        marginLeft: "auto",
        marginRight: "auto",
        borderBottom: "1px solid lightgray"
      }}
    >
      <Grid>
        <h2 style={{ textAlign: "center" }}>{area.name}</h2>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: has_indications ? "block" : "none" }}
      >
        {area_list.map(area => <p key={area.code}><b>{area.code}</b> {area.name}</p>)}
        <h3>What is the problem?</h3>
        <br />
        {indications.map((indication) => (
          <Accordion
            key={indication.name}
            expanded={expanded === indication.name}
            onChange={handleChange(indication.name)}
          >
            <AccordionSummary
              expandIcon={
                expanded === indication.name ? (
                  <RadioButtonCheckedIcon />
                ) : undefined
              }
              aria-controls={`${indication.name}-content`}
              id={`${indication.name}-header`}
            >
              <Typography>{indication.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <IndicationBar indication={indication} />
              {indication.description !== null ? (
                <Typography>
                  {indication.description}
                </Typography>
              ) : (
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      <Grid>
        {!has_indications && (
          <p>Looks like indications for this part has not yet been added</p>
        )}
      </Grid>
    </div>
  );
}
