import { Component } from "react";
import { createContext } from "react";

const MapContext = createContext(undefined);

export default MapContext;

function createIndex(objArr, key, unique = true) {
  var index = {};
  for (var i = 0; i < objArr.length; i++) {
    if (unique) {
      index[objArr[i][key]] = objArr[i];
    } else {
      if (objArr[i][key] in index) {
        index[objArr[i][key]].push(objArr[i]);
      } else {
        index[objArr[i][key]] = [objArr[i]];
      }
    }
  }
  return index;
}

function defineParents(area) {
  var parents = [];
  switch (area.id) {
    case 1:
    case 2:
    case 3:
      parents = ["Hair"];
      break;
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
      parents = ["Face"];
      break;
    case 23:
    case 24:
      parents = ["Neck"];
      break;
    case 25:
    case 26:
      parents = ["Chest"];
      break;
    case 27:
    case 28:
    case 29:
      parents = ["Abdomen"];
      break;
    case 30:
    case 31:
    case 32:
      parents = ["Back"];
      break;
    case 33:
    case 34:
    case 35:
    case 36:
      parents = ["Arm"];
      break;
    case 37:
    case 38:
      parents = ["Hand"];
      break;
    case 39:
    case 40:
      parents = ["Private"];
      break;
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
      parents = ["Leg"];
      break;
    case 46:
    case 47:
      parents = ["Foot"];
      break;
    default:
      parents = [];
  }
  return { ...area, parents: parents };
}

export class MapContextProvider extends Component {
  state = { data: undefined };
  async componentDidMount() {
    var data = await (
      await fetch("https://pdr.everlastdashboard.com:8443/bodymap")
    ).json();
    data.areas = data.areas.map(defineParents);
    data.areaIndications = createIndex(data.areaIndications, "area_id", false);
    data.indications = createIndex(data.indications, "id");
    data.indicationTreatments = createIndex(
      data.indicationTreatments,
      "indication_id",
      false
    );
    data.treatments = createIndex(data.treatments, "id");
    this.setState({ data: data });
  }
  render() {
    return (
      <MapContext.Provider value={{ data: this.state.data }}>
        {this.props.children}
      </MapContext.Provider>
    );
  }
}
