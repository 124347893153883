import { createContext, useState, useEffect } from "react";

// Extracted logic to get initial path
const getInitialPath = () => {
  return window.location.hash
    .split("?")[0]
    .replace(/#\//g, "")
    .split("/");
};

const ProgressContext = createContext({
  step: 0,
  setStep: undefined as any,
  nextPage: undefined as any,
  setNextPage: undefined as any,
  nextDisabled: true,
  backDisabled: true,
  next: undefined as any,
  back: undefined as any
});

export function ProgressContextProvider(props: { children: any }) {
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const [step, setStep] = useState(getInitialPath().length < 2 ? 0 : getInitialPath().length / 2);
  const { children } = props;

  useEffect(() => {
    // This function gets executed whenever the hash changes.
    const onHashChange = () => {
      const newStepValue = getInitialPath().length < 2 ? 0 : getInitialPath().length / 2;
      setStep(newStepValue);
    };

    // Attach the event listener
    window.addEventListener('hashchange', onHashChange);

    // Cleanup: detach the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, []); // The empty array means this useEffect runs once when the component mounts and when it unmounts.

  return (
    <ProgressContext.Provider
      value={{
        step,
        setStep,
        nextPage,
        setNextPage,
        nextDisabled: !nextPage,
        backDisabled: getInitialPath().length < 2,
        next: () => {
          var hash = window.location.hash
            .split("?")[0]
            .replace(/#\//g, "")
            .split("/")
            .join("/");
          console.log("Running Next on hash ", hash);
          if (hash.length) window.location.hash = `#/${hash}${nextPage}` || "/";
          else window.location.hash = `#${nextPage}`;
          setNextPage(undefined);
          setStep(step + 1);
        },
        back: () => {
          var hash_parts = window.location.hash
            .split("?")[0]
            .replace(/#\//g, "")
            .split("/");
          if (hash_parts.length <= 2) window.location.href = "/";
          else {
            hash_parts = hash_parts.slice(0, -2);
            window.location.hash = `#/${hash_parts.join("/")}`;
          }
          setNextPage(undefined);
          setStep(step - 1);
        },
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
}

export default ProgressContext;
